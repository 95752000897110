import "./set-public-path.js";
import React from "react";
import ReactDOMClient from "react-dom/client";
import singleSpaReact from "single-spa-react";
import singleSpaCanopy from "single-spa-canopy";
import { property } from "lodash";

const canopyLifecycles = singleSpaCanopy({
  domElementGetter,
  featureToggles: [],
  setPublicPath(publicPath: string) {
    __webpack_public_path__ = publicPath;
  },
  childAppName: "notes-ui",
});

const reactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  loadRootComponent: () =>
    import(/* webpackChunkName: "notes-ui-root" */ "./root.component.js").then(
      property("default")
    ),
  domElementGetter,
});

export const bootstrap = [
  canopyLifecycles.bootstrap,
  reactLifecycles.bootstrap,
];

export const mount = [reactLifecycles.mount, canopyLifecycles.mount];

export const unmount = [reactLifecycles.unmount, canopyLifecycles.unmount];

export const unload = [canopyLifecycles.unload];

function domElementGetter() {
  let el = document.getElementById("notes-ui");
  if (!el) {
    el = document.createElement("div");
    el.setAttribute("id", "notes-ui");
    document.body.appendChild(el);
  }

  return el;
}

export const getCreateEditNoteModal = () =>
  import(
    /* webpackChunkName: "create-edit-note-modal" */ "src/create-edit-note-modal/create-edit-note-modal.component.js"
  ).then((mod) => mod);
